import { Loading } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { selectLoadingState } from './appLoadingSlice';
import { useAppSelector } from '../../redux/store';

export function AppLoading() {
  const loadingState = useAppSelector(selectLoadingState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loadingState.some((p) => p.value === true));
  }, [loadingState]);

  return <>{isLoading === true && <Loading />}</>;
}
