import { Settings } from './settings/Settings';
import { Dashboard } from './dashboard/Dashboard';
import { NoMatch } from './404/NoMatch';
import { Route, Routes } from 'react-router-dom';
import { Help } from './help/Help';

export function Main() {
  return (
    <Routes>
      <Route path="404/*" element={<NoMatch />} />
      <Route path="settings/*" element={<Settings />} />
      <Route path="help/*" element={<Help />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}
