import './Header.css';
import { Tabs, Badge, Button, Tooltip, TabProps, InlineStack } from '@shopify/polaris';
import { useCallback, useEffect } from 'react';
import { selectShop, selectShopImpersonated } from '../shop/shopSlice';
import { PlayCircleMajor, QuestionMarkMajor, RefreshMajor, SettingsMajor } from '@shopify/polaris-icons';
import { selectHeaderMenuIndexSelected } from './redux/headerMenuSlice';
import { getProjectsAsync, syncProjectAsync } from '../dashboard/redux/projectsSlice';
import { getGeneralSettingsAsync } from '../settings/redux/generalSettingsSlice';
import { AppLoadingParts, selectLoadingState } from '../loading/appLoadingSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

export type HeaderMenuItem = TabProps & { redirectUrl: string; pro: boolean };

export class HeaderMenuItems {
  public static Dashboard: HeaderMenuItem = {
    id: 'dashboard',
    content: 'Dashboard',
    accessibilityLabel: 'Dashboard',
    panelID: 'dashboard-content',
    pro: false,
    redirectUrl: '/dashboard',
  };

  public static AllItems: Array<HeaderMenuItem> = [HeaderMenuItems.Dashboard];

  public static getHeaderMenuIndex(item: HeaderMenuItem): number {
    return HeaderMenuItems.AllItems.indexOf(item);
  }
}

export function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const shop = useAppSelector(selectShop);
  const impersonatedShop = useAppSelector(selectShopImpersonated);
  const menuIndexSelected = useAppSelector(selectHeaderMenuIndexSelected);
  const loadingState = useAppSelector(selectLoadingState);

  const refreshDashboard = useCallback(() => {
    dispatch(getProjectsAsync());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => refreshDashboard(), 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [refreshDashboard]);

  return (
    <div className={impersonatedShop == null ? 'app-header-container' : 'app-header-container impersonated-app-header-container'}>
      <InlineStack align="space-between" blockAlign="baseline">
        <InlineStack align="start">
          <Tabs
            tabs={HeaderMenuItems.AllItems}
            selected={menuIndexSelected ?? -1}
            onSelect={(selectedTabIndex) => {
              const item = HeaderMenuItems.AllItems[selectedTabIndex];

              if (item == null) {
                return;
              }

              if (item.pro === true && shop?.plan !== 'pro') {
                navigate(`/subscription`);
              } else {
                navigate(item.redirectUrl);
              }
            }}
          />
        </InlineStack>

        <InlineStack align="end" gap="500" wrap={false} blockAlign="start">
          <div className="first-item-container">
            <Badge tone={shop?.plan === 'pro' ? 'success' : 'warning'} size="small">
              {shop?.plan === 'pro' ? 'PRO' : 'FREE'}
            </Badge>
          </div>

          <Tooltip content="Refresh">
            <Button
              onClick={() => {
                refreshDashboard();
                dispatch(getGeneralSettingsAsync());
              }}
              icon={RefreshMajor}
              variant="tertiary"
            />
          </Tooltip>

          <Tooltip content="Schedule additional Tilda sync now">
            <Button
              onClick={() => dispatch(syncProjectAsync())}
              icon={PlayCircleMajor}
              variant="tertiary"
              disabled={loadingState.find((p) => p.key === AppLoadingParts.TildaProjectsSyncing)?.value === true}
            />
          </Tooltip>

          <Tooltip content="Go to Settings">
            <Button onClick={() => navigate('/settings')} icon={SettingsMajor} variant="tertiary" />
          </Tooltip>

          <div className="last-item-container">
            <Tooltip content="Go to Help">
              <Button onClick={() => navigate('/help')} icon={QuestionMarkMajor} variant="tertiary" />
            </Tooltip>
          </div>
        </InlineStack>
      </InlineStack>
    </div>
  );
}
