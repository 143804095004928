import { useLocation } from 'react-router-dom';
import { EmptyState } from '@shopify/polaris';

export function NoMatch() {
  const location = useLocation();

  return (
    <EmptyState heading="404. The page is not found" image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg">
      <p>{location.pathname}</p>
    </EmptyState>
  );
}
