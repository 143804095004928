import './Subscription.css';
import { useEffect, useState } from 'react';
import { Button, Card, Icon, TextField, Text, BlockStack, InlineStack, Box } from '@shopify/polaris';
import { CircleTickMajor } from '@shopify/polaris-icons';
import { BillingApiService } from '../../services/api/billing-api.service';
import { isCancel } from '../../services/api/utils';

export function Subscription() {
  const [url, setUrl] = useState<string>();
  const [code, setCode] = useState<string>();
  const [codeValid, setCodeValid] = useState<boolean>();
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    const run = async () => {
      try {
        setProcessing(true);
        const billingSvc = new BillingApiService();

        if (code == null || code === '') {
          setCodeValid(undefined);
          const billingUrl = await billingSvc.getShopifySubscriptionUrl(code);
          setUrl(billingUrl.data ?? undefined);
        } else {
          const valid = await billingSvc.verifyCoupon(code);

          if (valid.success !== true || valid.data !== true) {
            setCodeValid(false);
          } else {
            setCodeValid(true);
            const billingUrl = await billingSvc.getShopifySubscriptionUrl(code);
            setUrl(billingUrl.data ?? undefined);
          }
        }

        setProcessing(false);
      } catch (e) {
        if (!isCancel(e)) {
          throw e;
        }
      }
    };

    void run();
  }, [code]);

  return (
    <div className="subscription-container">
      <BlockStack inlineAlign="center">
        <Card>
          <Text as="h2" variant="headingSm">
            Get unlimited access to all Pro features now!
          </Text>

          <Box padding="300">
            <BlockStack align="start" gap="200" inlineAlign="start">
              <InlineStack align="start" blockAlign="baseline" gap="200">
                <Icon source={CircleTickMajor} tone="success" />
                <Text as="span" fontWeight="semibold">
                  3 days free trial
                </Text>
              </InlineStack>
              <InlineStack align="start" blockAlign="baseline" gap="200">
                <Icon source={CircleTickMajor} tone="success" />
                <p>Tilda synchronization</p>
              </InlineStack>
              <InlineStack align="start" blockAlign="baseline" gap="200">
                <Icon source={CircleTickMajor} tone="success" />
                <p>Tilda page updates monitoring</p>
              </InlineStack>
              <InlineStack align="start" blockAlign="baseline" gap="200">
                <Icon source={CircleTickMajor} tone="success" />
                <p>Advanced Shopify theme snippets support</p>
              </InlineStack>
            </BlockStack>
          </Box>

          <Box padding="300">
            <TextField
              label="Coupon code"
              value={code}
              onChange={(value) => setCode(value.trim())}
              error={codeValid === false ? 'Unknown coupon code' : undefined}
              helpText={codeValid === true ? 'Coupon code accepted' : null}
              autoComplete="off"
            />
          </Box>

          <Box padding="300">
            <InlineStack align="end">
              <Button
                url={url}
                external={true}
                disabled={processing === true || codeValid === false}
                loading={processing}
                variant="primary"
              >
                Upgrade to Pro
              </Button>
            </InlineStack>
          </Box>
        </Card>
      </BlockStack>
    </div>
  );
}
