import { ListResponseApiView, ITildaProjectApiView, SingleEntityResponseApiView } from '@app/common';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { formatUrl } from '../../utils/url.util';

export class TildaProjectApiService {
  async getAll(): Promise<ListResponseApiView<ITildaProjectApiView>> {
    const url = formatUrl('tilda/projects');

    const result = await axios.get<ListResponseApiView<ITildaProjectApiView>>(url);

    // parse dates
    result.data.data?.forEach((project) => {
      project.deletedAt = project.deletedAt == null ? undefined : new Date(project.deletedAt);

      project.pages.forEach((page) => {
        page.syncedAt = page.syncedAt == null ? undefined : new Date(page.syncedAt);
        page.deletedAt = page.deletedAt == null ? undefined : new Date(page.deletedAt);
      });
    });

    return result.data;
  }

  private static UpdateCancelTokenSource?: CancelTokenSource;
  async update(payload: ITildaProjectApiView) {
    if (TildaProjectApiService.UpdateCancelTokenSource != null) {
      TildaProjectApiService.UpdateCancelTokenSource.cancel();
      TildaProjectApiService.UpdateCancelTokenSource = undefined;
    }

    TildaProjectApiService.UpdateCancelTokenSource = axios.CancelToken.source();
    const result = await axios.put<unknown, AxiosResponse<SingleEntityResponseApiView<ITildaProjectApiView>>>(
      formatUrl(`tilda/projects/${payload.id}`),
      payload,
      {
        cancelToken: TildaProjectApiService.UpdateCancelTokenSource.token,
      },
    );
    TildaProjectApiService.UpdateCancelTokenSource = undefined;

    return result.data;
  }
}
