import { SingleEntityResponseApiView } from '@app/common';
import axios from 'axios';
import { formatUrl } from '../../utils/url.util';

export class TildaSyncApiService {
  async sync(): Promise<SingleEntityResponseApiView<void>> {
    const url = formatUrl('tilda/sync');
    const result = await axios.post<SingleEntityResponseApiView<void>>(url);
    return result.data;
  }
}
