import { useEffect, useState } from 'react';
import { AuthApiService } from '../../services/api/auth-api.service';
import { ProgressBar } from '@shopify/polaris';
import { setShopMe } from '../shop/shopSlice';
import { LogService } from '../../services/log.service';
import { useAppDispatch } from '../../redux/store';
import { SHOPIFY_PRICE_FREE } from '../../configs/config';
import { BillingApiService } from '../../services/api/billing-api.service';

export function Auth() {
  const dispatch = useAppDispatch();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((progress) => (progress >= 100 ? 0 : progress + 10));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    LogService.log('Authenticating');

    const run = async () => {
      const authSvc = new AuthApiService();
      await authSvc.login();
      const shop = await authSvc.authenticate(window.location.search);

      if (shop.data && shop.data.plan === 'free' && (SHOPIFY_PRICE_FREE as unknown) === false) {
        const billingSvc = new BillingApiService();
        const billingUrl = await billingSvc.getShopifySubscriptionUrl();
        if (billingUrl.data) {
          window.open(billingUrl.data, '_top');
          return;
        }
      }

      dispatch(setShopMe(shop.data ?? undefined));
    };

    void run();
  }, [dispatch]);

  return (
    <div className="starting-progress-container">
      <ProgressBar progress={progress} />
    </div>
  );
}
