import { Box, Card, EmptyState, Layout } from '@shopify/polaris';
import { FallbackProps } from 'react-error-boundary';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className="narrow-section-container">
      <Layout.Section variant="oneHalf">
        <Card>
          <Box padding="300">
            <EmptyState
              heading="Something went wrong:"
              action={{ content: 'Try again', onAction: () => resetErrorBoundary() }}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
              <p>{error.message}</p>
            </EmptyState>
          </Box>
        </Card>
      </Layout.Section>
    </div>
  );
}
