import './Settings.css';
import { ISettingsApiView, TargetThemeModes } from '@app/common';
import { Button, Card, Checkbox, FormLayout, InlineStack, Layout, Link, Select, TextField, Text } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { setHeaderMenuIndexSelected } from '../header/redux/headerMenuSlice';
import { AppLoadingParts, selectLoadingState } from '../loading/appLoadingSlice';
import { getGeneralSettingsAsync, saveGeneralSettingsAsync, selectGeneralSettings } from './redux/generalSettingsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';

export function Settings() {
  const dispatch = useAppDispatch();

  const settings = useAppSelector(selectGeneralSettings);
  const loadingState = useAppSelector(selectLoadingState);

  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const [localSettings, setLocalSettings] = useState<Partial<ISettingsApiView>>();

  // update the header menu item selected
  useEffect(() => {
    dispatch(setHeaderMenuIndexSelected(null));
  }, [dispatch]);

  useEffect(() => {
    setSaving(loadingState.find((p) => p.key === AppLoadingParts.SettingsGeneralSaving)?.value ?? false);
    setLoading(loadingState.find((p) => p.key === AppLoadingParts.SettingsGeneralLoading)?.value ?? false);
  }, [loadingState]);

  useEffect(() => {
    setLocalSettings({ ...settings });
    setDirty(false);
  }, [settings]);

  const save = () => {
    dispatch(
      saveGeneralSettingsAsync({
        ...settings,
        ...localSettings,
      }),
    );
  };

  return (
    <Layout>
      <div className="narrow-section-container">
        <div className="settings-header-container">
          <InlineStack align="end" gap="500">
            <Button disabled={dirty === false || loading === true || saving === true} onClick={() => dispatch(getGeneralSettingsAsync())}>
              Discard
            </Button>

            <Button disabled={dirty === false || loading === true || saving === true} variant="primary" onClick={() => save()}>
              Save
            </Button>
          </InlineStack>
        </div>

        <Layout.AnnotatedSection
          title="Tilda API keys"
          description={
            <>
              Application will use the keys to extract data from Tilda. You can find them in&nbsp;
              <Link url="https://tilda.cc/?r=3964311&lang=en" external={true}>
                your account
              </Link>
              <p>Select a Project Settings -&gt; Export -&gt; API Intergation.</p>
              <p>
                Please make sure that{' '}
                <Text as="span" fontWeight="semibold">
                  MASTER KEY
                </Text>{' '}
                setting is enabled.
              </p>
            </>
          }
        >
          <Card>
            <FormLayout>
              <TextField
                label="Public key:"
                onChange={(value) => {
                  setLocalSettings({ ...localSettings, publicKey: value === '' ? undefined : value });
                  setDirty(true);
                }}
                value={localSettings?.publicKey}
                autoComplete="off"
                spellCheck={false}
                placeholder={'Enter your public key here'}
              />
              <TextField
                label="Secret key:"
                onChange={(value) => {
                  setLocalSettings({ ...localSettings, secretKey: value === '' ? undefined : value });
                  setDirty(true);
                }}
                value={localSettings?.secretKey}
                autoComplete="off"
                spellCheck={false}
                placeholder={'Enter your secret key here'}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection title="Target themes" description="Application will sync Tilda pages to the defined themes">
          <Card>
            <FormLayout>
              <Select
                label=""
                options={[
                  { label: 'Only to the main theme', value: 'main' },
                  { label: 'To all themes', value: 'all' },
                ]}
                onChange={(value) => {
                  setLocalSettings({ ...localSettings, targetThemesMode: value as TargetThemeModes });
                  setDirty(true);
                }}
                value={localSettings?.targetThemesMode}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Automatic sync"
          description="When enabled, the app will sync all the pages selected for syncing automatically every 10 mins"
        >
          <Card>
            <FormLayout>
              <Checkbox
                label={localSettings?.automaticSyncEnabled === true ? 'Enabled' : 'Disabled'}
                checked={localSettings?.automaticSyncEnabled}
                onChange={(value) => {
                  setLocalSettings({ ...localSettings, automaticSyncEnabled: value });
                  setDirty(true);
                }}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Top-level snippets"
          description="If specified, the application will add the snippets to the very top and bottom of each page"
        >
          <Card>
            <FormLayout>
              <TextField
                label="Top snippet:"
                onChange={(value) => {
                  setLocalSettings({ ...localSettings, topSnippet: value === '' ? undefined : value });
                  setDirty(true);
                }}
                value={localSettings?.topSnippet}
                autoComplete="off"
                spellCheck={false}
                placeholder={'Enter your top snippet here'}
              />
              <TextField
                label="Bottom snippet:"
                onChange={(value) => {
                  setLocalSettings({ ...localSettings, bottomSnippet: value === '' ? undefined : value });
                  setDirty(true);
                }}
                value={localSettings?.bottomSnippet}
                autoComplete="off"
                spellCheck={false}
                placeholder={'Enter your bottom snippet here'}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </div>
    </Layout>
  );
}
