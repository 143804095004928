import { ISettingsApiView } from '@app/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../redux/store';
import { LogService } from '../../../services/log.service';
import { showBanner } from '../../banner/appBannerSlice';
import { AppLoadingParts, startLoading, stopLoading } from '../../loading/appLoadingSlice';
import { SettingsApiService } from '../../../services/api/settings-api.service';
import { isCancel } from '../../../services/api/utils';

export type GeneralSettingsState = Partial<ISettingsApiView>;

const InitialState: GeneralSettingsState = {};

export const generalSettingsSlice = createSlice({
  name: 'generalSettings',
  initialState: InitialState,
  reducers: {
    setGeneralSettings: (_state, action: PayloadAction<Partial<ISettingsApiView>>) => action.payload,
  },
});

export const { setGeneralSettings } = generalSettingsSlice.actions;

export const getGeneralSettingsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(AppLoadingParts.SettingsGeneralLoading));

    const svc = new SettingsApiService();
    const response = await svc.get();

    dispatch(setGeneralSettings(response?.data || {}));
  } catch (e) {
    LogService.error(`Error while loading general settings`);
    LogService.error(e);
  } finally {
    dispatch(stopLoading(AppLoadingParts.SettingsGeneralLoading));
  }
};

export const saveGeneralSettingsAsync =
  (item: Partial<ISettingsApiView>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(AppLoadingParts.SettingsGeneralSaving));

      const svc = new SettingsApiService();
      const response = await svc.update(item);

      if (response.success === false) {
        dispatch(showBanner({ status: 'critical', title: response.message ?? 'Error while saving the settings' }));
      } else {
        dispatch(setGeneralSettings(response?.data || {}));
      }

      dispatch(stopLoading(AppLoadingParts.SettingsGeneralSaving));
    } catch (e) {
      if (isCancel(e) === false) {
        LogService.error(`Error while saving general settings`);
        LogService.error(e);
        dispatch(stopLoading(AppLoadingParts.SettingsGeneralSaving));
      }
    }
  };

export const selectGeneralSettings = (state: RootState) => state.settings.general;

export default generalSettingsSlice.reducer;
