import { ITildaProjectApiView } from '@app/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../redux/store';
import { TildaProjectApiService } from '../../../services/api/tilda-project-api.service';
import { TildaSyncApiService } from '../../../services/api/tilda-sync-api.service';
import { isCancel } from '../../../services/api/utils';
import { LogService } from '../../../services/log.service';
import { showBanner } from '../../banner/appBannerSlice';
import { AppLoadingParts, startLoading, stopLoading } from '../../loading/appLoadingSlice';
import { showToast } from '../../toast/appToastSlice';

export interface DashboardProjectsState {
  items: Array<ITildaProjectApiView>;
}

const InitialState: DashboardProjectsState = {
  items: [],
};

export const projectsSlice = createSlice({
  name: 'dashboardProjects',
  initialState: InitialState,
  reducers: {
    resetProjects: () => InitialState,
    setItems: (state, action: PayloadAction<Array<ITildaProjectApiView>>) => {
      state.items = action.payload;
    },
  },
});

export const { setItems, resetProjects } = projectsSlice.actions;

export const getProjectsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(AppLoadingParts.DashboardTildaProjectsLoading));
    const svc = new TildaProjectApiService();

    const projects = await svc.getAll();
    dispatch(setItems(projects.data));
  } catch (e) {
    LogService.error(`Error while loading projects`);
    LogService.error(e);
  } finally {
    dispatch(stopLoading(AppLoadingParts.DashboardTildaProjectsLoading));
  }
};

export const updateProjectAsync =
  (project: ITildaProjectApiView): AppThunk =>
  async (dispatch, state) => {
    try {
      dispatch(startLoading(AppLoadingParts.DashboardTildaProjectsSaving));

      const svc = new TildaProjectApiService();
      const response = await svc.update(project);

      const current = selectProjects(state());
      dispatch(setItems(current.items.map((p) => (p.id === response.data?.id ? response.data : p))));

      dispatch(stopLoading(AppLoadingParts.DashboardTildaProjectsSaving));
    } catch (e) {
      if (isCancel(e) === false) {
        LogService.error(`Error while saving project`);
        LogService.error(e);
        dispatch(showBanner({ status: 'critical', title: `Error while saving project. Please try again or contact support` }));
        dispatch(stopLoading(AppLoadingParts.DashboardTildaProjectsSaving));
      }
    }
  };

export const syncProjectAsync = (): AppThunk => async (dispatch, state) => {
  try {
    dispatch(startLoading(AppLoadingParts.TildaProjectsSyncing));

    const svc = new TildaSyncApiService();
    const response = await svc.sync();

    if (response.success === true) {
      dispatch(showToast({ error: false, content: `Successfully scheduled`, duration: 2000 }));
    } else {
      dispatch(showToast({ error: true, content: response.message ?? 'Failed to schedule', duration: 2000 }));
    }
  } catch (e) {
    LogService.error(`Error while scheduling projects sync`);
    LogService.error(e);
  } finally {
    dispatch(stopLoading(AppLoadingParts.TildaProjectsSyncing));
  }
};

export const selectProjects = (state: RootState) => state.dashboard.projects;

export default projectsSlice.reducer;
