import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { formatUrl } from '../../utils/url.util';
import { SingleEntityResponseApiView } from '@app/common';

export class BillingApiService {
  private static ShopifySubscriptionUrlCancelTokenSources?: CancelTokenSource;
  async getShopifySubscriptionUrl(coupon?: string): Promise<SingleEntityResponseApiView<string>> {
    if (BillingApiService.ShopifySubscriptionUrlCancelTokenSources != null) {
      BillingApiService.ShopifySubscriptionUrlCancelTokenSources.cancel();
    }

    BillingApiService.ShopifySubscriptionUrlCancelTokenSources = axios.CancelToken.source();
    const result = await axios.post<unknown, AxiosResponse<SingleEntityResponseApiView<string>>>(
      formatUrl(`billing/shopify/subscription`),
      { coupon },
      {
        cancelToken: BillingApiService.ShopifySubscriptionUrlCancelTokenSources.token,
      },
    );
    BillingApiService.ShopifySubscriptionUrlCancelTokenSources = undefined;

    return result.data;
  }

  private static VerifyCouponCancelTokenSources?: CancelTokenSource;
  async verifyCoupon(coupon: string): Promise<SingleEntityResponseApiView<boolean>> {
    if (BillingApiService.VerifyCouponCancelTokenSources != null) {
      BillingApiService.VerifyCouponCancelTokenSources.cancel();
    }

    BillingApiService.VerifyCouponCancelTokenSources = axios.CancelToken.source();
    const result = await axios.post<unknown, AxiosResponse<SingleEntityResponseApiView<boolean>>>(
      formatUrl(`billing/coupon/verify`),
      { coupon },
      {
        cancelToken: BillingApiService.VerifyCouponCancelTokenSources.token,
      },
    );
    BillingApiService.VerifyCouponCancelTokenSources = undefined;

    return result.data;
  }
}
