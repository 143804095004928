import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';

export type AppBannerStateStatus = 'success' | 'info' | 'warning' | 'critical';

export interface AppBannerState {
  show: boolean;
  status?: AppBannerStateStatus;
  title?: string;
  content: React.ReactNode;
}

export const InitialState: AppBannerState = {
  show: false,
  status: undefined,
  title: undefined,
  content: null,
};

export const appBannerSlice = createSlice({
  name: 'appBanner',
  initialState: InitialState,
  reducers: {
    showBanner: (state, action: PayloadAction<{ status: AppBannerStateStatus; title: string; content?: React.ReactNode }>) => {
      state.show = true;
      state.status = action.payload.status;
      state.title = action.payload.title;
      state.content = action.payload.content;
    },
    hideBanner: () => InitialState,
  },
});

export const { showBanner, hideBanner } = appBannerSlice.actions;

export const selectAppBannerStatus = (state: RootState) => state.appBanner;

export default appBannerSlice.reducer;
