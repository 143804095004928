import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';

export type HeaderMenuState = { selectedIndex: number | null };

const InitialState: HeaderMenuState = {
  selectedIndex: null,
};

export const headerMenuSlice = createSlice({
  name: 'headerMenu',
  initialState: InitialState,
  reducers: {
    setHeaderMenuIndexSelected: (state, action: PayloadAction<number | null>) => {
      state.selectedIndex = action.payload;
    },
  },
});

export const { setHeaderMenuIndexSelected } = headerMenuSlice.actions;

export const selectHeaderMenuIndexSelected = (state: RootState) => state.header.menu.selectedIndex;

export default headerMenuSlice.reducer;
