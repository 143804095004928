import './Footer.css';
import { FooterHelp, Link, Text, Autocomplete } from '@shopify/polaris';
import { VERSION } from '../../configs/config';
import { useEffect, useState } from 'react';
import { getAllShopsAsync, selectShop, selectShopsAll, selectShopMe, setShopImpersonated } from '../shop/shopSlice';
import { IShopApiView } from '@app/common';
import { AuthApiService } from '../../services/api/auth-api.service';
import { LogService } from '../../services/log.service';
import { resetProjects } from '../dashboard/redux/projectsSlice';
import { hideBanner } from '../banner/appBannerSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';

export function Footer() {
  const dispatch = useAppDispatch();

  const me = useAppSelector(selectShopMe);
  const shop = useAppSelector(selectShop);
  const shops = useAppSelector(selectShopsAll);

  const [searchValue, setSearchValue] = useState<string>();
  const [filteredShops, setFilteredShops] = useState<IShopApiView[]>([]);

  useEffect(() => {
    if (me?.impersonalization === true) {
      dispatch(getAllShopsAsync());
    }
  }, [dispatch, me]);

  useEffect(() => {
    setSearchValue(shop?.origin);
  }, [shop?.origin]);

  useEffect(() => {
    setFilteredShops(shops);
  }, [shops]);

  return (
    <FooterHelp>
      <Link url="mailto:app.shopify.tilda@gmail.com" external={true}>
        Need Help?
      </Link>

      <span className="footer-version-container">
        <Text as="span" tone="subdued">
          v{VERSION}
        </Text>
      </span>

      {me?.impersonalization === true && (
        <Autocomplete
          options={filteredShops
            .map((s) => {
              return { value: `${s.id}`, label: s.origin };
            })
            .sort((a, b) => a.label.localeCompare(b.label))}
          selected={shop?.id == null ? [] : [`${shop.id}`]}
          onSelect={(selected) => {
            const selectedShop = shops.find((s) => `${s.id}` === selected[0]);

            if (selectedShop == null) {
              LogService.error(`Selected shop is invalid ${JSON.stringify(selected)}`);
              return;
            }

            setFilteredShops(shops);

            const authSvc = new AuthApiService();
            authSvc.setImpersonatedShop(selectedShop);

            // reset dashboard data
            dispatch(resetProjects());

            // hide banner
            dispatch(hideBanner());

            dispatch(setShopImpersonated(selectedShop));
          }}
          textField={
            <Autocomplete.TextField
              autoComplete="off"
              onChange={(value) => {
                setSearchValue(value);
                setFilteredShops(shops.filter((s) => value == null || value === '' || s.origin.includes(value)));
              }}
              label="Shop"
              labelHidden
              value={searchValue}
              placeholder="Search shop"
            />
          }
        />
      )}
    </FooterHelp>
  );
}
