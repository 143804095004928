export type ThemeTemplateType =
  | 'no_template'
  | 'article'
  | 'blog'
  | 'cart'
  | 'collection'
  | 'gift_card'
  | 'index'
  | 'page'
  | 'product'
  | 'search'
  | '404'
  | 'list-collections'
  | 'customers/account'
  | 'customers/addresses'
  | 'customers/login'
  | 'customers/order'
  | 'customers/activate_account'
  | 'customers/reset_password'
  | 'customers/register'
  | 'password';

export const THEME_TEMPLATE_TYPES = [
  'no_template',
  'article',
  'blog',
  'cart',
  'collection',
  'gift_card',
  'index',
  'page',
  'product',
  'search',
  '404',
  'list-collections',
  'customers/account',
  'customers/addresses',
  'customers/login',
  'customers/order',
  'customers/activate_account',
  'customers/reset_password',
  'customers/register',
  'password',
];
