import { IShopApiView } from '@app/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../redux/store';
import { ShopApiService } from '../../services/api/shop-api.service';
import { LogService } from '../../services/log.service';
import { setCurrency } from '../../utils/currency.util';
import { AppLoadingParts, startLoading, stopLoading } from '../loading/appLoadingSlice';

export interface ShopState {
  me?: IShopApiView;
  impersonated?: IShopApiView;
  all: IShopApiView[];
}

const InitialState: ShopState = {
  me: undefined,
  impersonated: undefined,
  all: [],
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState: InitialState,
  reducers: {
    setShopMe: (state, action: PayloadAction<IShopApiView | undefined>) => {
      state.me = action.payload;
      setCurrency(action.payload?.currency ?? undefined);
    },
    setShopImpersonated: (state, action: PayloadAction<IShopApiView>) => {
      state.impersonated = action.payload;
      setCurrency(action.payload.currency);
    },
    setShopsAll: (state, action: PayloadAction<IShopApiView[]>) => {
      state.all = action.payload;
    },
  },
});

export const { setShopMe, setShopsAll, setShopImpersonated } = shopSlice.actions;

export const getAllShopsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(AppLoadingParts.Shops));

    const svc = new ShopApiService();
    const response = await svc.getAllShops();

    dispatch(setShopsAll(response.data));
  } catch (e) {
    LogService.error(`Error while loading all shops`);
    LogService.error(e);
  } finally {
    dispatch(stopLoading(AppLoadingParts.Shops));
  }
};

export const selectShop = (state: RootState) => state.shop.impersonated ?? state.shop.me;
export const selectShopMe = (state: RootState) => state.shop.me;
export const selectShopImpersonated = (state: RootState) => state.shop.impersonated;
export const selectShopsAll = (state: RootState) => state.shop.all;

export default shopSlice.reducer;
