import { SingleEntityResponseApiView } from '@app/common';
import axios, { AxiosResponse } from 'axios';
import { formatUrl } from '../../utils/url.util';

export class ClientErrorApiService {
  async log(error: { message: string; name: string; stack?: string }): Promise<SingleEntityResponseApiView<void>> {
    const result = await axios.post<unknown, AxiosResponse<SingleEntityResponseApiView<void>>>(formatUrl(`client-error/log`), error);
    return result.data;
  }
}
