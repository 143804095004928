import { Banner } from '@shopify/polaris';
import { hideBanner, selectAppBannerStatus } from './appBannerSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';

export function AppBanner() {
  const bannerStatus = useAppSelector(selectAppBannerStatus);
  const dispatch = useAppDispatch();

  return (
    <>
      {bannerStatus.show && (
        <div className="banner-container">
          <Banner title={bannerStatus.title} tone={bannerStatus.status} onDismiss={() => dispatch(hideBanner())}>
            {bannerStatus.content}
          </Banner>
        </div>
      )}
    </>
  );
}
