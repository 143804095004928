import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import shopReducer from '../features/shop/shopSlice';
import dashboardReducer from '../features/dashboard/redux/dashboard.reducer';
import appBannerReducer from '../features/banner/appBannerSlice';
import appLoadingReducer from '../features/loading/appLoadingSlice';
import settingsReducer from '../features/settings/redux/settings.reducer';
import appToastReducer from '../features/toast/appToastSlice';
import headerReducer from '../features/header/redux/header.reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const store = configureStore({
  reducer: combineReducers({
    shop: shopReducer,
    dashboard: dashboardReducer(),
    appBanner: appBannerReducer,
    appToast: appToastReducer,
    appLoading: appLoadingReducer,
    settings: settingsReducer(),
    header: headerReducer(),
  }),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
