import { ClientErrorApiService } from './api/client-error-api.service';

export abstract class LogService {
  public static debug(message: string): void {
    console.debug(LogService.format(message));
  }

  public static log(message: string): void {
    console.log(LogService.format(message));
  }

  public static warn(message: string): void {
    console.warn(LogService.format(message));
  }

  public static error(error: string | Error | unknown, logToServer = false): void {
    let errorToLog: Error = error as Error;
    if (!(error instanceof Error)) {
      errorToLog = new Error(LogService.format(error));
    }

    console.error(errorToLog);

    if (logToServer === true) {
      const svc = new ClientErrorApiService();
      void svc.log({ message: errorToLog.message, name: errorToLog.name, stack: errorToLog.stack });
    }
  }

  private static format(message: string | unknown): string {
    if (typeof message == 'object') {
      return `[APP] ${JSON.stringify(message)}`;
    }

    return `[APP] ${message as string}`;
  }
}
