import { ISettingsApiView, SingleEntityResponseApiView } from '@app/common';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { formatUrl } from '../../utils/url.util';

export class SettingsApiService {
  async get(): Promise<SingleEntityResponseApiView<ISettingsApiView>> {
    const result = await axios.get<SingleEntityResponseApiView<ISettingsApiView>>(formatUrl(`settings`));
    return result.data;
  }

  private static UpdateCancelTokenSource?: CancelTokenSource;
  async update(payload: Partial<ISettingsApiView>) {
    if (SettingsApiService.UpdateCancelTokenSource != null) {
      SettingsApiService.UpdateCancelTokenSource.cancel();
      SettingsApiService.UpdateCancelTokenSource = undefined;
    }

    SettingsApiService.UpdateCancelTokenSource = axios.CancelToken.source();
    const result = await axios.put<unknown, AxiosResponse<SingleEntityResponseApiView<ISettingsApiView>>>(formatUrl('settings'), payload, {
      cancelToken: SettingsApiService.UpdateCancelTokenSource.token,
    });
    SettingsApiService.UpdateCancelTokenSource = undefined;

    return result.data;
  }
}
