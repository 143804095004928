export class SingleEntityResponseApiView<T> {
  constructor(
    public data: T | null = null,
    public success: boolean = true,
    public message: string | null = null,
  ) {}
}

export class ListResponseApiView<T> {
  constructor(
    public data: T[],
    public totalCount: number = data.length,
    public success: boolean = true,
    public message: string | null = null,
  ) {}
}

export interface PaginationInfo<S> {
  page: number;
  pageSize: number;
  orderBy: S;
  orderDirection: OrderDirection;
}

export interface SelectionInfo {
  selectedResources: string[];
  allResourcesSelected: boolean;
}

export type OrderDirection = 'ASC' | 'DESC';
