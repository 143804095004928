import { Layout, Card, Text, List, Banner, Link, InlineCode } from '@shopify/polaris';
import { useEffect } from 'react';
import { setHeaderMenuIndexSelected } from '../header/redux/headerMenuSlice';
import { useAppDispatch } from '../../redux/store';

export function Help() {
  const dispatch = useAppDispatch();

  // update the header menu item selected
  useEffect(() => {
    dispatch(setHeaderMenuIndexSelected(null));
  }, [dispatch]);

  return (
    <>
      <Layout>
        <Layout.AnnotatedSection title="How to get Tilda account?">
          <Card>
            <p>
              You can create it by using{' '}
              <Link url="https://tilda.cc/?r=3964311&lang=en" external={true}>
                the link
              </Link>
              .
            </p>
            <p>Please take into account that you will need a plan that allows a source code export.</p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="How does the app actually work?">
          <Card>
            <p>The very basic sync scenario includes the following steps:</p>
            <List type="number">
              <List.Item>Taking a list of pages to be synced</List.Item>
              <List.Item>Getting the latest page contents from Tilda</List.Item>
              <List.Item>
                Storing the page content as a Shopify theme snippet
                <Banner>
                  There is a file size limitation of 256 KB per snippet, so sometimes we are splitting the page content into several
                  snippets and combining all of them in a root one with{' '}
                  <Text as="span">
                    <InlineCode>{'{% include %}'}</InlineCode>
                  </Text>
                </Banner>
              </List.Item>
            </List>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="What does ‘Sync all pages’ flag mean?">
          <Card>
            <p>
              The flag means that all pages of the project will be synced. It also means that all future pages of the project will be added
              to the sync scope automatically.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="How often does the sync run?">
          <Card>
            <p>
              We are syncing approximately
              <Text as="span" fontWeight="semibold">
                every 10 minutes
              </Text>{' '}
              in the background. And you can always trigger the sync manually from the app interface.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Do we have any limits?">
          <Card>
            <p>
              Unfortunately yes. Tilda provides{' '}
              <Link url="http://help.tilda.ws/api" external={true}>
                150 API calls
              </Link>
              per hour. The app is highly optimized request-wise but sometimes you may face the error message. Do not worry then, the app
              proceeds syncing when the limitation is gone.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Should I always sync all pages?">
          <Card>
            <p>
              You can, but if there is no such need, we recommend syncing only the pages that are used in your shop to keep your theme clear
              and prevent going beyond Tilda API limits.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="What is a page type?">
          <Card>
            <p>
              If you select a page type different from{' '}
              <Text as="span">
                <InlineCode>no template</InlineCode>
              </Text>
              , a template of a specified type will be created additionally to a snippet. Template files are special theme files that can be
              assigned to a product, page, etc. It is important to select a proper type because Shopify treats each particular template type
              in a unique way. For example, if you set{' '}
              <Text as="span">
                <InlineCode>blog</InlineCode>
              </Text>{' '}
              type to a Tilda page, you won’t be able to assign a Shopify product to the created template.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="What if I need to assign a Tilda page to some predefined Shopify page?">
          <Card>
            <p>
              You can include a created snippet into the page template. For example, in order to use it in{' '}
              <Text as="span">
                <InlineCode>index.liquid</InlineCode>
              </Text>
              , just include it like{' '}
              <Text as="span">
                <InlineCode>{'{% include `tilda_page.frontpage` %}'}</InlineCode>
              </Text>
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="How can I include some Shopify features into Tilda pages?">
          <Card>
            <p>
              Since a synced page is treated as a liquid, you can include any valid liquid into Tilda page as{' '}
              <Link url="https://help.tilda.ws/html" external={true}>
                Embed HTML Code
              </Link>{' '}
              or{' '}
              <Link url="https://help.tilda.ws/zero" external={true}>
                HTML of Zero block
              </Link>
              . So you can create your custom snippet in a theme and just use{' '}
              <Text as="span">
                <InlineCode>{'{% include ... %}'}</InlineCode>
              </Text>{' '}
              liquid on the Tilda side.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="How to create a product page on Tilda?">
          <Card>
            <p>
              The app works best for not product pages like main, contacts, sales, etc but you are still able to create a product page
              though. Please follow the steps:
            </p>
            <List type="number">
              <List.Item>Create a separate page on Tilda</List.Item>
              <List.Item>Mark the page to be synced in the app. Set `product` template type for the page</List.Item>
              <List.Item>
                In Shopify go to the product settings on Admin and select the template from the templates dropdown (you should find it in
                the Online store block on the bottom right side of the product settings page)
              </List.Item>
            </List>

            <p>In order to add a buy button to the page do the following:</p>
            <List type="number">
              <List.Item>
                Create a new snippet file in your Shopify theme that contains the button code. The button code example is below but you can
                modify it as you need:
                <Text as="span">
                  <InlineCode>
                    {`<form method="post" action="/cart/add">
                      <input type="hidden" name="id" value="{{ product.variants.first.id }}" />
                      <input min="1" type="number" id="quantity" name="quantity" value="1" />
                      <input type="submit" value="Add to cart" className="btn" />
                    </form>`}
                  </InlineCode>
                </Text>
              </List.Item>
              <List.Item>
                Include the snippet somewhere in the Tilda template by adding an HTML injection with the code{' '}
                <Text as="span">
                  <InlineCode>{`{% include 'buy-button' %}`}</InlineCode>
                </Text>
                . Please check that the snippet name corresponds
              </List.Item>
              <List.Item>Sync the page into your Shopify theme</List.Item>
            </List>
            <p>The snippet code should be injected on the Shopify theme rendering into the place where you put it on the Tilda side.</p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Do we sync only published pages?">
          <Card>
            <p>
              We sync all selected pages, but Tilda returns{' '}
              <Text as="span">
                <InlineCode>Page not published</InlineCode>
              </Text>{' '}
              content for those that are not published. And Tilda does not apply your changes until you publish the page. So you should
              publish it in order to be properly synced.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Does the app delete anything from a Shopify theme?">
          <Card>
            <p>
              No. We only create snippets, templates and overrides them during the next sync if there are any changes on the Tilda side.
              This means when a page is synced to a theme and then you delete it from Tilda or exclude it from the sync, the page snippet
              and the template won&apos;t be removed from your theme.
            </p>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="How to keep synced files in a theme if I work with it via Shopify Theme Kit?">
          <Card>
            <p>A couple of things to consider here:</p>
            <List type="number">
              <List.Item>
                All synced files are named with{' '}
                <Text as="span">
                  <InlineCode>tilda_</InlineCode>
                </Text>{' '}
                prefix, so you can ignore them in your dev environment with{' '}
                <Text as="span">
                  <InlineCode>.gitignore</InlineCode>
                </Text>{' '}
                for example.
              </List.Item>
              <List.Item>
                When using Shopify Theme Kit, please consider using{' '}
                <Text as="span">
                  <InlineCode>--nodelete</InlineCode>
                </Text>{' '}
                flag to not delete the synced files. See more details in the{' '}
                <Link url="https://shopify.github.io/themekit/commands/" external={true}>
                  Documentation
                </Link>
              </List.Item>
            </List>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Can I define a template type even in Tilda?">
          <Card>
            <p>
              Yes, you can. Just start your page name with{' '}
              <Text as="span">
                <InlineCode>type and dot</InlineCode>
              </Text>
              . For example,{' '}
              <Text as="span">
                <InlineCode>page.my-page</InlineCode>
              </Text>{' '}
              or{' '}
              <Text as="span">
                <InlineCode>product.the-product</InlineCode>
              </Text>
              , and the app will preselect the type for the page.
            </p>
            <p>The list of available types is:</p>
            <List type="bullet">
              <List.Item>article</List.Item>
              <List.Item>blog</List.Item>
              <List.Item>cart</List.Item>
              <List.Item>collection</List.Item>
              <List.Item>gift_card</List.Item>
              <List.Item>index</List.Item>
              <List.Item>page</List.Item>
              <List.Item>product</List.Item>
              <List.Item>search</List.Item>
              <List.Item>404</List.Item>
              <List.Item>list-collections</List.Item>
              <List.Item>customers/account</List.Item>
              <List.Item>customers/addresses</List.Item>
              <List.Item>customers/login</List.Item>
              <List.Item>customers/order</List.Item>
              <List.Item>customers/activate_account</List.Item>
              <List.Item>customers/reset_password</List.Item>
              <List.Item>customers/register</List.Item>
              <List.Item>password</List.Item>
            </List>
            <Banner>Please take into account that the page type is not automatically changed for a page if you rename it in Tilda.</Banner>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
}
