import { Toast } from '@shopify/polaris';
import { hideToast, selectAppToastStatus } from './appToastSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';

export function AppToast() {
  const toastStatus = useAppSelector(selectAppToastStatus);
  const dispatch = useAppDispatch();

  return (
    <>
      {toastStatus.show && (
        <Toast
          error={toastStatus.error}
          content={toastStatus.content ?? ''}
          duration={toastStatus.duration}
          onDismiss={() => dispatch(hideToast())}
        />
      )}
    </>
  );
}
