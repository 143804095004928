import { IShopApiView, ListResponseApiView } from '@app/common';
import axios from 'axios';
import { formatUrl } from '../../utils/url.util';

export class ShopApiService {
  async getAllShops(): Promise<ListResponseApiView<IShopApiView>> {
    const result = await axios.get<ListResponseApiView<IShopApiView>>(formatUrl('shops'));
    return result.data;
  }
}
