import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';

export enum AppLoadingParts {
  Shops,
  DashboardTildaProjectsLoading,
  DashboardTildaProjectsSaving,
  SettingsGeneralLoading,
  SettingsGeneralSaving,
  TildaProjectsSyncing,
}

export interface AppLoadingState {
  parts: Array<{ key: AppLoadingParts; value: boolean }>;
}

const InitialState: AppLoadingState = {
  parts: [],
};

export const appLoadingSlice = createSlice({
  name: 'appLoading',
  initialState: InitialState,
  reducers: {
    startLoading: (state, action: PayloadAction<AppLoadingParts>) => {
      const part = state.parts.find((p) => p.key === action.payload);
      if (part != null) {
        part.value = true;
      } else {
        state.parts.push({ key: action.payload, value: true });
      }
    },
    stopLoading: (state, action: PayloadAction<AppLoadingParts>) => {
      const part = state.parts.find((p) => p.key === action.payload);
      if (part != null) {
        part.value = false;
      } else {
        state.parts.push({ key: action.payload, value: false });
      }
    },
  },
});

export const { startLoading, stopLoading } = appLoadingSlice.actions;

export const selectLoadingState = (state: RootState) => state.appLoading.parts;

export default appLoadingSlice.reducer;
