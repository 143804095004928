import './App.css';
import '@shopify/polaris/build/esm/styles.css';
import { AppProvider, Frame } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { Route, Routes } from 'react-router-dom';
import { selectShop } from './features/shop/shopSlice';
import { Auth } from './features/auth/Auth';
import { AppBanner } from './features/banner/AppBanner';
import { AppLoading } from './features/loading/AppLoading';
import { AppToast } from './features/toast/AppToast';
import { Footer } from './features/footer/Footer';
import { ErrorBoundary } from 'react-error-boundary';
import { LogService } from './services/log.service';
import { Main } from './features/Main';
import { ErrorFallback } from './features/error/ErrorFallback';
import { Header } from './features/header/Header';
import { useEffect } from 'react';
import { getGeneralSettingsAsync, selectGeneralSettings } from './features/settings/redux/generalSettingsSlice';
import { Subscription } from './features/billing/Subscription';
import { showBanner } from './features/banner/appBannerSlice';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './redux/store';

export function App() {
  const dispatch = useAppDispatch();

  const shop = useAppSelector(selectShop);
  const settings = useAppSelector(selectGeneralSettings);

  useEffect(() => {
    if (shop != null) {
      dispatch(getGeneralSettingsAsync());
    }
  }, [dispatch, shop]);

  useEffect(() => {
    if (
      settings.id != null &&
      (settings.publicKey == null || settings.publicKey === '' || settings.secretKey == null || settings.secretKey === '')
    ) {
      dispatch(
        showBanner({
          status: 'warning',
          title: 'Tilda API keys are missing',
          content: (
            <>
              Please provide them in the app <RouterLink to="/settings">settings</RouterLink>
            </>
          ),
        }),
      );
    }
  }, [dispatch, settings]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <AppProvider i18n={enTranslations}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(e) => LogService.error(e, true)}>
        {shop == null && (
          <Routes>
            <Route path="/*" element={<Auth />} />
          </Routes>
        )}

        {shop != null && (
          <Frame key={shop.id}>
            <Header />
            <div className="main-container">
              <AppLoading />
              <AppBanner />
              <AppToast />
              <Routes>
                <Route path="/subscription/*" element={<Subscription />} />
                <Route path="/*" element={<Main />} />
              </Routes>
              <Footer />
            </div>
          </Frame>
        )}
      </ErrorBoundary>
    </AppProvider>
  );
}
