import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';

export interface AppToastState {
  show: boolean;
  error?: boolean;
  content?: string;
  duration?: number;
}

export const InitialState: AppToastState = {
  show: false,
  error: undefined,
  content: undefined,
  duration: undefined,
};

export const appToastSlice = createSlice({
  name: 'appToast',
  initialState: InitialState,
  reducers: {
    showToast: (state, action: PayloadAction<{ error: boolean; content: string; duration: number }>) => {
      state.show = true;
      state.error = action.payload.error;
      state.content = action.payload.content;
      state.duration = action.payload.duration;
    },
    hideToast: () => InitialState,
  },
});

export const { showToast, hideToast } = appToastSlice.actions;

export const selectAppToastStatus = (state: RootState) => state.appToast;

export default appToastSlice.reducer;
