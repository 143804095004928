import './Dashboard.css';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapsible,
  DataTable,
  Divider,
  EmptyState,
  InlineStack,
  Layout,
  Select,
  Text,
} from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { getProjectsAsync, selectProjects, updateProjectAsync } from './redux/projectsSlice';
import { AppLoadingParts, selectLoadingState } from '../loading/appLoadingSlice';
import { ThemeTemplateType, THEME_TEMPLATE_TYPES } from '@app/common';
import { TildaSyncApiService } from '../../services/api/tilda-sync-api.service';
import { showToast } from '../toast/appToastSlice';
import { LogService } from '../../services/log.service';
import { HeaderMenuItems } from '../header/Header';
import { setHeaderMenuIndexSelected } from '../header/redux/headerMenuSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

export function Dashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const projects = useAppSelector(selectProjects);
  const loadingState = useAppSelector(selectLoadingState);

  const [openedProjects, setOpenedProjects] = useState<Array<{ id: string; opened: boolean }>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getProjectsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (projects.items.length === 0) {
      const interval = setInterval(() => {
        LogService.debug('Refreshing empty dashboard');
        dispatch(getProjectsAsync());
      }, 1000 * 5);
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, projects.items.length]);

  useEffect(() => {
    if (openedProjects.length === 0) {
      setOpenedProjects(
        [...projects.items]
          .sort((a, b) => (a.id < b.id ? 1 : a.id === b.id ? 0 : -1))
          .map((project, i) => {
            return { id: project.id, opened: i === 0 ? true : false };
          }),
      );
    }
  }, [openedProjects, projects.items]);

  // update the header menu item selected
  useEffect(() => {
    const headerMenuItemIndex: number = HeaderMenuItems.getHeaderMenuIndex(HeaderMenuItems.Dashboard);
    dispatch(setHeaderMenuIndexSelected(headerMenuItemIndex));
  }, [dispatch]);

  useEffect(() => {
    setLoading(
      loadingState.find((p) => p.key === AppLoadingParts.DashboardTildaProjectsLoading)?.value === true ||
        loadingState.find((p) => p.key === AppLoadingParts.DashboardTildaProjectsSaving)?.value === true,
    );
  }, [loadingState]);

  return (
    <Layout>
      <Layout.Section variant="fullWidth">
        {projects.items.length > 0 &&
          [...projects.items]
            .sort((a, b) => (a.id < b.id ? 1 : a.id === b.id ? 0 : -1))
            .map((project) => (
              <div key={project.id} className="dashboard-project-container">
                <Card>
                  <Box>
                    <InlineStack align="space-between">
                      <Text as="h2" variant="headingSm">
                        {`${project.title ?? ''} (${project.tildaId})${project.deletedAt == null ? '' : ' (Deleted)'}`}
                      </Text>

                      <Button
                        onClick={() => {
                          setOpenedProjects(openedProjects.map((p) => (p.id === project.id ? { id: project.id, opened: !p.opened } : p)));
                        }}
                      >
                        {openedProjects.find((p) => p.id === project.id)?.opened === true ? 'Less' : 'More'}
                      </Button>
                    </InlineStack>

                    <Collapsible
                      open={openedProjects.find((p) => p.id === project.id)?.opened ?? false}
                      id="project-collapsible"
                      transition={{ duration: '150ms', timingFunction: 'ease' }}
                    >
                      <div className="dashboard-project-selectall-container">
                        <Checkbox
                          disabled={loading === true || project.deletedAt != null}
                          label="Select all pages (current and those that will be created in the future)"
                          checked={project.syncAllPages}
                          onChange={(value) => {
                            dispatch(updateProjectAsync({ ...project, syncAllPages: value }));
                          }}
                        />
                      </div>
                      <Divider />

                      <DataTable
                        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric']}
                        headings={['Title', 'Tilda ID', 'Type', 'Sync', 'Snippet name', 'Last sync']}
                        rows={[...project.pages]
                          .sort((a, b) => (a.id < b.id ? 1 : a.id === b.id ? 0 : -1))
                          .map((page) => [
                            page.title,
                            `${page.tildaId}${page.deletedAt == null ? '' : ' (deleted)'}`,
                            <Select
                              disabled={loading === true || project.deletedAt != null || page.deletedAt != null}
                              key={page.id}
                              label=""
                              options={THEME_TEMPLATE_TYPES}
                              onChange={(value) => {
                                dispatch(
                                  updateProjectAsync({
                                    ...project,
                                    pages: project.pages.map((p) => (p.id === page.id ? { ...p, type: value as ThemeTemplateType } : p)),
                                  }),
                                );
                              }}
                              value={page.type}
                            />,
                            <Checkbox
                              label={null}
                              key={page.id}
                              checked={project.syncAllPages || page.sync}
                              disabled={project.syncAllPages || loading === true || project.deletedAt != null || page.deletedAt != null}
                              onChange={(value) => {
                                dispatch(
                                  updateProjectAsync({
                                    ...project,
                                    pages: project.pages.map((p) => (p.id === page.id ? { ...p, sync: value } : p)),
                                  }),
                                );
                              }}
                            />,
                            page.assetName ?? '-',
                            page.syncedAt == null ? '-' : new Date(page.syncedAt).toLocaleString(),
                          ])}
                      />
                    </Collapsible>
                  </Box>
                </Card>
              </div>
            ))}

        {projects.items.length === 0 && (
          <Card>
            <EmptyState
              heading="No Tilda projects"
              action={{
                content: 'Settings',
                onAction: () => {
                  navigate(`/settings`);
                },
              }}
              secondaryAction={{
                content: 'Sync now',
                onAction: async () => {
                  const svc = new TildaSyncApiService();
                  await svc.sync();
                  dispatch(showToast({ error: false, content: `Successfully scheduled`, duration: 2000 }));
                },
              }}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
              <p>There are no Tilda projects loaded for the moment. Please check keys in the settings or launch Tilda sync right now.</p>
            </EmptyState>
          </Card>
        )}
      </Layout.Section>
    </Layout>
  );
}
