import { Currency } from '@app/common';

export let ShopCurrency: Currency = 'USD';
export let ShopCurrencySympol = '$';

export function setCurrency(value: Currency = 'USD') {
  ShopCurrency = value;
  ShopCurrencySympol = formatCurrency(0)
    .replace(/[0-9.,]/g, '')
    .trim();
}

export function formatCurrency(value: number | undefined) {
  const currency = ShopCurrency ?? 'USD';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(value ?? 0);
}
