import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { LogService } from '../../services/log.service';
import { formatUrl } from '../../utils/url.util';
import { SingleEntityResponseApiView, IJwtToken, IShopApiView, ImpersonatedShopHeaderName } from '@app/common';

export class AuthApiService {
  async login(): Promise<void> {
    const token = await shopify.idToken();

    if (axios.defaults.headers.common == null) {
      axios.defaults.headers.common = {};
    }

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    this.scheduleRefresh(token);
  }

  private scheduleRefresh(token: string) {
    const decoded = jwtDecode<IJwtToken>(token);
    const now = Date.now();

    let refresh = decoded.exp * 1000 - 5000 - now;
    refresh = refresh <= 0 ? 1 : refresh;

    setTimeout(() => {
      void (async () => {
        await this.login();
      })();
    }, refresh);

    LogService.debug(`JWT token refresh is scheduled in ${refresh} ms`);
  }

  setImpersonatedShop(shop: IShopApiView): void {
    if (axios.defaults.headers.common == null) {
      axios.defaults.headers.common = {};
    }

    axios.defaults.headers.common[ImpersonatedShopHeaderName] = `${shop.id}`;
  }

  async authenticate(qs: string): Promise<SingleEntityResponseApiView<IShopApiView | null>> {
    try {
      const result = await axios.get<SingleEntityResponseApiView<IShopApiView>>(formatUrl(`auth/shopify${qs}`));

      return result.data;
    } catch (e) {
      const error = e as { response: { data: { statusCode: number } } };

      if (error.response?.data?.statusCode === 401) {
        return { data: null, success: false } as SingleEntityResponseApiView<IShopApiView | null>;
      } else {
        throw e;
      }
    }
  }
}
